<template>
    <div class="delivery-putdown-container">
        <van-pull-refresh v-model="refreshLoading" @refresh="onSearch">
            <!-- 悬浮按钮 -->
            <div class="scan-option-btn">
                <van-icon name="filter-o" size="20px" @click="picker.showOptions=!picker.showOptions" dot/>
            </div>
            <div class="delivery-putdown-header">
                <van-nav-bar title="下车扫描" fixed/>
            </div>
            <!-- 扫描区域 -->
            <div class="scan-body">
                <!-- 汇总区域 -->
                <div class="scan-sum van-list">
                    <div class="title">
                        <van-row justify="center" class="van-cell">
                            <van-col span="6">
                                <span>配送站</span>
                            </van-col>
                            <van-col span="6">
                                <span>总计</span>
                            </van-col>
                            <van-col span="6">
                                <span>已扫</span>
                            </van-col>
                            <van-col span="6">
                                <span>未扫</span>
                            </van-col>
                        </van-row>
                    </div>
                    <template v-if="sumList.length > 0">
                        <van-row justify="center" v-for="(item, index) in sumList" v-bind:key="index" class="van-cell">
                            <van-col span="6">
                                <span>{{item.site}}</span>
                            </van-col>
                            <van-col span="6">
                                <van-tag round type="primary">{{item.total}}</van-tag>
                            </van-col>
                            <van-col span="6">
                                <van-tag round type="success">{{item.putdown}}</van-tag>
                            </van-col>
                            <van-col span="6">
                                <van-tag v-if="item.total-item.putdown > 0" round type="danger">
                                    {{item.total-item.putdown}}
                                </van-tag>
                                <van-tag v-if="item.total-item.putdown == 0" round>{{item.total-item.putdown}}</van-tag>
                            </van-col>
                        </van-row>
                    </template>
                    <div v-if="sumList.length == 0" class="tips" style="text-align: left">
                        <van-steps>
                            <van-step>设置条件</van-step>
                            <van-step>开始扫描</van-step>
                        </van-steps>
                    </div>
                </div>
                <!-- 列表区域 -->
                <div v-if="list.length > 0" class="tips">
                    <h2>扫描结果</h2>
                </div>
                <div class="scan-list" v-show="list.length > 0">
                    <van-list v-model="loading" :finished="finished" finished-text="扫描成功" @load="onLoad">
                        <van-cell v-for="(item, index) in list" :key="index" :title="item.goodsNo"
                                  :label="item.goodsName + '（' + item.goodsSpec + '）*' + item.goodsAmount"
                                  :value="item.site"/>
                    </van-list>
                </div>
                <!-- 按钮区域 -->
                <div class="scan-btn" v-if="sumList.length > 0">
                    <van-button v-show="env!='browser'&&!selfInput" icon="scan" type="info" size="large"
                                @click="doScan"
                                text="开始扫描"/>
                    <van-cell-group v-show="env=='browser'||selfInput" class="self-input">
                        <van-field
                                v-model="goodsNo"
                                type="number"
                                maxlength="15"
                                oninput="value=value.replace(/[^\d]/g, '')"
                                center
                                clearable
                                placeholder="请输入商品条码"
                                input-align="center"
                        >
                            <van-button slot="button" icon="passed" size="large" type="info" @click="doPutout">提交</van-button>
                        </van-field>
                    </van-cell-group>
                </div>
            </div>
        </van-pull-refresh>
        <!-- 弹出区域 -->
        <div class="scan-popup">
            <!-- 设置 -->
            <van-popup v-model="picker.showOptions" position="right" :round="true" :style="{width:'80%'}">
                <!-- 设置区域 -->
                <div class="scan-option">
                    <!-- 说明区域 -->
                    <van-steps>
                        <van-step>设置条件</van-step>
                        <van-step>开始扫描</van-step>
                    </van-steps>
                    <!-- 表单区域 -->
                    <div class="scan-option-form">
                        <van-cell-group>
                            <van-field
                                    v-model="option.sendDate"
                                    required
                                    label="配送日期"
                                    placeholder="请选择配送日期"
                                    @focus="picker.selectSendDate=true"
                                    readonly
                            />
                            <van-field
                                    v-model="option.startTime"
                                    required
                                    label="开始时间"
                                    placeholder="请选择开始时间"
                                    @focus="picker.selectStartTime=true"
                                    readonly
                            />
                            <van-field
                                    v-model="option.endTime"
                                    required
                                    label="结束时间"
                                    placeholder="请选择结束时间"
                                    @focus="picker.selectEndTime=true"
                                    readonly
                            />
                            <van-field
                                    v-model="option.cityName"
                                    required
                                    label="配送城市"
                                    placeholder="请选择配送城市"
                                    @focus="picker.selectCity=true"
                                    readonly
                            />
                            <van-field v-show="option.cityId"
                                       v-model="option.siteName"
                                       required
                                       label="配送站"
                                       placeholder="请选择配送站"
                                       @focus="onPickerSite"
                                       readonly
                            />
                            <van-switch-cell v-model="selfInput" title="手动输入" v-show="env!='browser'"/>
                            <van-button type="info" icon="passed" size="large" @click="onSearch"
                                        style="position:fixed;bottom:0;"
                                        text="保存并查询"/>
                        </van-cell-group>
                    </div>
                </div>
            </van-popup>
            <!-- 配送日期 -->
            <van-popup v-model="picker.selectSendDate" position="bottom">
                <van-datetime-picker
                        title="配送日期"
                        :visible-item-count="12"
                        v-model="currentDate"
                        type="date"
                        @confirm="onChangeSendDate"
                        @cancel="picker.selectSendDate=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 开始时间 -->
            <van-popup v-model="picker.selectStartTime" position="bottom">
                <van-datetime-picker
                        title="开始时间"
                        :visible-item-count="12"
                        v-model="option.startTime"
                        type="time"
                        :filter="timeFilter"
                        :min-hour="minHour"
                        :max-hour="maxHour"
                        @confirm="picker.selectStartTime=false"
                        @cancel="picker.selectStartTime=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 结束时间 -->
            <van-popup v-model="picker.selectEndTime" position="bottom">
                <van-datetime-picker
                        title="结束时间"
                        :visible-item-count="12"
                        v-model="option.endTime"
                        type="time"
                        :filter="timeFilter"
                        :min-hour="minHour"
                        :max-hour="maxHour"
                        @confirm="picker.selectEndTime=false"
                        @cancel="picker.selectEndTime=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 配送城市 -->
            <van-popup v-model="picker.selectCity" position="bottom">
                <van-picker
                        title="配送城市"
                        :visible-item-count="12"
                        :columns="cityList"
                        @confirm="onChangeCity"
                        @cancel="picker.selectCity=false"
                        :show-toolbar="true"
                />
            </van-popup>
            <!-- 配送站 -->
            <van-popup v-model="picker.selectSite" position="bottom">
                <van-picker
                        title="配送站"
                        :visible-item-count="12"
                        :columns="siteList"
                        @confirm="onChangeSite"
                        @cancel="picker.selectSite=false"
                        :show-toolbar="true"
                />
            </van-popup>
        </div>
    </div>
</template>
<script>
    import {mapMutations} from 'vuex'
    export default {
        name: 'delivery_putout',
        data() {
            return {
                // 汇总信息列表
                sumList: [],
                // 城市列表
                cityList: [],
                // 配送站列表
                siteList: [],
                // 配送站列表缓存
                siteListCache: [],
                // 选项
                option: {
                    scanType: 'putdown',
                    sendDate: '',
                    startTime: '10:00',
                    endTime: '22:00',
                    cityId: 0,
                    cityName: '',
                    siteId: 0,
                    siteName: ''
                },
                // 选择器
                picker: {
                    showOptions: false,
                    selectSendDate: false,
                    selectStartTime: false,
                    selectEndTime: false,
                    selectCity: false,
                    selectSite: false
                },
                // 最小小时
                minHour: 10,
                // 最大小时
                maxHour: 22,
                // 最小日期
                minDate: new Date(),
                // 最大日期
                maxDate: new Date(),
                // 当前日期
                currentDate: new Date(),
                // 扫描列表
                list: [],
                // 加载状态
                refreshLoading: false,
                // 加载状态
                loading: false,
                // 加载完成
                finished: false,
                // 音频列表（临时存储）
                audio: {
                    default: 'voicePrompt/error.wav',
                    errorSite: 'voicePrompt/shangcuozhan.wav',
                    errorSeep: 'voicePrompt/seepOperate.wav',
                    errorCancel: 'voicePrompt/bill_cancel.wav',
                    errorStatus: 'voicePrompt/zhuangtaibuzu.wav',
                    multiCake: 'voicePrompt/multicake.wav',
                },
                // 商品条码
                goodsNo: '',
                // 环境变量
                env: 'browser',
                // 手动输入模式
                selfInput: false,
                // 隐藏显示数字键盘
                showKeyboard: false,
            }
        },
        beforeRouteEnter(to, from, next) {
            window.document.body.style.background = '#efeff4'
            next()
        },
        beforeRouteLeave(to, from, next) {
            window.document.body.style.background = '#ffffff'
            next()
        },
        mounted: function () {
            this.init()
        },
        activated: function () {

        },
        methods: {
            ...mapMutations(['changeWxConfig']),
            // 初始化
            init() {
                this.envInit()
                let that = this
                that.$api.user.loadCity().then(response => {
                    let res = response.data
                    if (res.code == 1) {
                        that.cityList = res.data
                    } else {
                        that.$toast(res.msg)
                    }
                })
            },
            // 初始化环境变量
            envInit() {
                if (navigator.userAgent.toLowerCase().indexOf('micromessenger') !== -1) {
                    this.env = 'weixin'
                }
                if (this.$dd.env.platform !== 'notInDingTalk') {
                    this.env = 'dingding'
                }
            },
            // 加载已扫描列表
            onLoad() {
                // 加载状态结束
                this.loading = false;
                this.finished = true;
            },
            // 切换配送日期
            onChangeSendDate(value) {
                let date = value
                this.option.sendDate = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate()
                this.picker.selectSendDate = false
            },
            // 切换配送城市
            onChangeCity(value) {
                // 清空已选配送站和配送站
                if (this.option.cityId !== value.index) {
                    this.option.siteId = 0
                    this.option.siteName = ''
                }
                this.option.cityId = value.index
                this.option.cityName = value.text
                this.picker.selectCity = false
            },
            // 激活配送站选择器
            onPickerSite() {
                if (this.option.cityId == 0)
                    return this.$toast.fail('请选择配送城市')
                let that = this
                let cache = []
                if (this.siteListCache.length > 0) {
                    this.siteListCache.forEach(function (v) {
                        if (v.cityId == that.option.cityId) {
                            cache = v.list
                        }
                    })
                }
                if (cache.length == 0) {
                    that.$api.user.loadSite(that.option.cityId).then(response => {
                        let res = response.data
                        if (res.code == 1) {
                            that.siteListCache.push({cityId: that.option.cityId, list: res.data})
                            that.siteList = res.data
                            that.picker.selectSite = true
                        } else {
                            that.$toast(res.msg)
                        }
                    })
                } else {
                    this.siteList = cache
                    this.picker.selectSite = true
                }
            },
            // 切换配送站
            onChangeSite(value) {
                this.option.siteId = value.index
                this.option.siteName = value.text
                this.picker.selectSite = false
            },
            // 时间选择规则
            timeFilter(type, options) {
                if (type === 'minute') {
                    return options.filter(option => option % 15 === 0)
                }
                return options;
            },
            // 保存设置
            onSearch() {
                this.refreshLoading = false
                if (!this.option.sendDate)
                    return this.$toast.fail('请选择配送日期')
                if (!this.option.startTime)
                    return this.$toast.fail('请选择开始时间')
                if (!this.option.endTime)
                    return this.$toast.fail('请选择结束时间')
                if (!this.option.cityId)
                    return this.$toast.fail('请选择配送城市')
                if (!this.option.siteId)
                    return this.$toast.fail('请选择配送站')
                this.picker.showOptions = false
                let that = this
                that.refreshLoading = true
                that.$api.delivery.loadSummary(that.option).then(response => {
                    that.refreshLoading = false
                    let res = response.data
                    if (res.code == 1) {
                        if (res.data.length == 0) {
                            that.$toast('暂无数据')
                            that.sumList = []
                        } else {
                            that.$toast('刷新成功')
                            that.sumList = res.data
                        }
                    } else {
                        that.$toast(res.msg)
                    }
                })
            },
            // 开始扫描
            doScan() {
                switch (this.env) {
                    case 'dingding':
                        this.ddScan()
                        break
                    case 'weixin':
                        this.wxScan()
                        break
                    default:
                        this.$toast.fail('请在钉钉或微信内使用扫一扫功能')
                }
            },
            // 钉钉扫码
            ddScan() {
                let that = this
                that.$dd.ready(function () {
                    that.$dd.biz.util.scan({
                        type: 'barCode', // type 为 all、qrCode、barCode，默认是all。
                        onSuccess: function (data) {
                            that.goodsNo = data.text
                            return that.doPutout()
                        },
                        onFail: function (err) {
                            that.$toast.fail(err)
                        }
                    })
                })
            },
            // 微信扫码
            wxScan() {

                if (!this.$store.state.wxConfig) {
                    let that = this
                    let options = {
                        url: window.location.href
                    }
                    that.$api.wechat.sign(options).then(response => {
                        let res = response.data
                        if (res.code == 1) {
                            that.$wx.config({
                                debug: false,
                                appId: res.data.appId,
                                timestamp: res.data.timestamp,
                                nonceStr: res.data.nonceStr,
                                signature: res.data.signature,
                                jsApiList: [
                                    'scanQRCode'
                                ]
                            })
                            that.changeWxConfig(true)
                            that.doWxScan()
                        } else {
                            that.$toast.fail(res.msg)
                        }
                    })
                } else {
                    this.doWxScan()
                }
            },
            doWxScan() {
                let that = this
                that.$wx.ready(function () {
                    that.$wx.scanQRCode({
                        needResult: 1, // 默认为0，扫描结果由微信处理，1则直接返回扫描结果，
                        scanType: ['barCode'], // 可以指定扫二维码还是一维码，默认二者都有
                        success: function (res) {
                            that.goodsNo = res.resultStr
                            return that.doPutout()
                        }
                    })
                })
            },
            // 开始扫描
            doPutout() {
                if (!/^\d+$/.test(this.goodsNo)) {
                    this.playError()
                    return this.$toast.fail('商品条码错误')
                }
                let that = this
                try {
                    that.list.forEach(function (v) {
                        if (parseInt(v.goodsNo) == parseInt(that.goodsNo)) {
                            throw new Error('重复扫描')
                        }
                    })
                } catch (e) {
                    that.goodsNo = ''
                    this.playError()
                    return that.$toast.fail(e.message)
                }
                let option = that.option
                option.goodsNo = that.goodsNo
                that.$api.delivery.putdown(option).then(response => {
                    let res = response.data
                    that.goodsNo = ''
                    if (res.code == 1) {
                        that.$toast.success('扫描成功')
                        that.sumList.forEach(function (v, k) {
                            if (v.siteId == res.data.info.siteId && parseInt(v.putdown) < parseInt(v.total)) {
                                that.sumList[k].putdown = parseInt(v.putdown) + parseInt(res.data.info.goodsAmount)
                            }
                        })
                        that.list.unshift(res.data.info)
                    } else {
                        that.playError()
                        that.$toast.fail(res.msg)
                    }
                    if (res.data.audio && res.data.audio.length > 0) {
                        let audioList = res.data.audio
                        let num = 0
                        audioList.forEach(function (src) {
                            num++
                            if (num > 1) {
                                setTimeout(function () {
                                    that.playError(src)
                                }, 1000)
                            } else {
                                that.playError(src)
                            }
                        })
                    }
                })
            },
            // 播放错误提示音
            playError(src = '') {
                let audio = new Audio()
                audio.src = 'https://img.mcake.com/' + (!src ? this.audio.default : src)
                audio.play()
            }
        },
        watch: {}
    }
</script>
<style scoped>
    .delivery-putdown-header {
        height: 46px;
    }

    .scan-option-btn {
        position: fixed;
        top: 58px;
        z-index: 2;
        right: 10px;
    }

    .scan-list {
        margin-bottom: 55px;
        max-height: 260px;
        overflow-y: scroll;
    }

    .scan-btn {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
    }

    .scan-sum {
        text-align: center;
        max-height: 315px;
        overflow-y: scroll;
    }

    .scan-sum .title {
        height: 45px;
        width: 100%;
    }

    div.tips h2 {
        font-size: 14px;
        font-weight: normal;
        color: rgba(69, 90, 100, 0.6);
        padding: 10px;
        text-align: center;
    }

    .scan-sum .title .van-row {
        position: fixed;
        top: 46px;
        z-index: 1;
        background-color: #f9f9f9;
    }

    .van-list .van-button {
        margin-left: 5px;
    }

    .van-popup {
        width: 100%;
        height: 100%;
    }

    .scan-sum .van-row {
        border-bottom: 1px solid #efeff4;
    }

    .self-input {
        line-height: 30px;
        padding: 0;
    }

    .self-input .van-cell {
        padding: 0;
    }

    .self-input .van-button {
        padding: 0 20px;
    }
</style>
